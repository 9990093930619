import { Form, Spin, Table } from "antd";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useEffect, useState } from "react";
import NumberInputFormat from "component/NumberInputFormat";
import { Styles } from "./styles";
import { ICareCategorySetting } from "types/admin";
import { formatNumber, unFormat } from "utils/formats";
import { toast } from 'react-toastify';
import { useQuery } from "react-query";
import apiFactory from "api";

const SettingNursingCareLevel = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm();
  const { data, refetch, isLoading, isFetching } = useQuery(["careCategorySettings"], () =>
    apiFactory.adminCareCategorySettings.getList<{
      data: ICareCategorySetting[];
    }>()
  );
  const columns = [
    {
      title: "介護区分",
      dataIndex: "name",
      key: "name",
      render: (value: string, item: any) => {
        return (
          <>
            <span>{value}</span>
            <Form.Item name={[item.id.toString(), "name"]} hidden>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "単位数",
      dataIndex: "unit",
      key: "unit",
      render: (value: number, item: any) => {
        if (!isEdit) return formatNumber(value + "");
        return (
          <Form.Item name={[item.id.toString(), "unit"]}>
            <NumberInputFormat maxLen={18}  type="math" />
          </Form.Item>
        );
      },
    },
    {
      title: "金額(円)",
      dataIndex: "price",
      key: "price",
      render: (value: number, item: any) => {
        if (!isEdit) return formatNumber(value + "");
        return (
          <Form.Item name={[item.id.toString(), "price"]}>
             <NumberInputFormat maxLen={18}  type="math" />
          </Form.Item>
        );
      },
    },
  ];

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true)
    const keysOfValues = Object.keys(values);
    const payload = keysOfValues.map((key) => {
      const value = values[key];
      return {
        ...value,
        unit: value.unit ? unFormat(value.unit) : "0",
        price: value.price ? unFormat(value.price) : "0",
        id: Number(key),
      };
    });

    try {
     const res =  await apiFactory.adminCareCategorySettings.update<any>({ data: payload })
      console.log(res?.success)
      toast.success(res?.success)
     await refetch()
     setIsEdit(false)
    } catch (e :any) {
      e?.error && toast.error(e?.error)
    } finally {
      setIsSubmitting(false)
    }
  };

  useEffect(() => {
    if (data?.data) {
      const dataForm = data?.data?.reduce((acc: any, cur: ICareCategorySetting) => {
        acc[cur.id] = {
          ...cur,
          unit: formatNumber(cur.unit + ""),
          price: formatNumber(cur.price + ""),
        };
        return acc;
      }, {});
      form.setFieldsValue(dataForm);
    }
  }, [isEdit]);

  return (
    <Spin spinning={isSubmitting || isLoading || isFetching}>
      <Styles>
      <div className="page-title">管理メニュー　プロフィール</div>
      <div className="page-container">
        <div
          className={`text-right ${isEdit ? "h-0 opacity-0" : "h-14"}`}
          style={{ transition: "0.2s" }}
        >
          <ButtonSolid
            onClick={() => {
              setIsEdit(true);
            }}
          >
            編集
          </ButtonSolid>
        </div>

        <div className={isEdit ? "mode-edit" : ""}>
          <Form form={form} onFinish={handleSubmit}>
            <Table
              columns={columns}
              dataSource={data?.data || []}
              pagination={false}
            />
          </Form>
        </div>

        {isEdit && (
          <div className="text-right mt-4">
            <ButtonOutline
              className="mr-[8px]"
              onClick={() => {
                setIsEdit(false);
              }}
            >
              キャンセル
            </ButtonOutline>
            <ButtonSolid
              width={137}
              isLoading={isSubmitting}
              onClick={() => {
                form.submit();
              }}
            >
              保存
            </ButtonSolid>
          </div>
        )}
      </div>
    </Styles>
    </Spin>
  );
};

export default SettingNursingCareLevel;
