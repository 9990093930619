import { Modal, Checkbox } from "antd";
import styled from "@emotion/styled";

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    padding: 16px;
    padding-right: 8px
  }
  .ant-modal-footer {
    margin: 0;
  }
  .ant-select-selector:not(.ant-picker-status-error) {
    border-color: #00000026 !important;
  }
  .ant-input {
    border-color: #d9d9d9
  }
`;

export default ModalStyled;
