import { forwardRef, Fragment, useEffect } from "react";
import { Styled, TablelStyled } from "./styled";
import { Flex } from "@chakra-ui/react";
import dayjs, { Dayjs } from "dayjs";
import { IItemService } from "types/admin";
import moment from "moment";
import { DATE_ERA_FORMAT_YEAR_MONTH, DATE_FORMAT_2 } from "constant/date";
import { IServiceType } from "constant/interfaces";
import DiagonalLine from "../../DiagonalLine";
import { roundToDecimal } from "utils/formats";

interface IProps {
  dataPrint: IItemService[] | null;
  setIsReadyPrint: React.Dispatch<React.SetStateAction<boolean>>;
  currentDate?: Dayjs;
  serviceTypes: IServiceType[];
}

const ServiceProvisionRecord = (props: IProps, ref: any) => {
  const { dataPrint, currentDate, serviceTypes, setIsReadyPrint } = props;

  const monthSelected = moment(dayjs(currentDate).format(DATE_FORMAT_2)).format(
    DATE_ERA_FORMAT_YEAR_MONTH
  );

  const getJsxSeparate = (
    maxLen: number,
    text?: string | null,
    rowSpan?: number
  ) => {
    const textElements: JSX.Element[] = [];
    for (let i = 0; i < maxLen; i++) {
      if (text && text[i]) {
        textElements.push(
          <td className="text-center" rowSpan={rowSpan} key={i}>
            <div>{text[i]}</div>
          </td>
        );
      } else {
        textElements.push(
          <td className="text-center" rowSpan={rowSpan} key={i}>
            <div className="opacity-0">0</div>
          </td>
        );
      }
    }
    return textElements;
  };

  useEffect(() => {
    setIsReadyPrint(true);
  }, []);

  function timeSubTractTime(timeStart: string, timeEnd: string) {
    const [hoursStart, minutesStart] = timeStart.split(":").map(Number);
    const [hoursEnd, minutesEnd] = timeEnd.split(":").map(Number);

    const timeInMinuteStart = hoursStart * 60 + minutesStart;
    const timeInMinuteEnd = hoursEnd * 60 + minutesEnd;
    const difference = Math.abs((timeInMinuteEnd - timeInMinuteStart) / 60);
    return roundToDecimal(difference, 2); // convert minute to hour
  }

  return (
    <div className="fixed -right-[9999px] -top-[9999px]">
      <Styled ref={ref}>
        {dataPrint?.map((item) => {
          const { client, recipient, profile, shifts } = item;
          let totalHourSchudele = 0 
          const rowSchedules = shifts.map((shift, indexShift) => {
            return shift.schedules
              .sort((a, b) => {
                if (
                  a.start_time_format + a.end_time_format <
                  b.start_time_format + b.end_time_format
                )
                  return -1;
                else return 1;
              })
              .map((schedule, indexShedule) => {
                const isHistory = schedule.actual > 0; // shedule is gray refer at admin/shift/shift-management?currentDate=2024%2F12%2F02&scrollTop=0

                const scheduleStartTime = schedule.start_time_format;
                const scheduleEndTime = schedule.end_time_format;
                const historyStartTime = isHistory
                  ? schedule.nursing_care_history.start_time_format
                  : "";
                const historyEndTime = isHistory
                  ? schedule.nursing_care_history.end_time_format
                  : "";

                const hourSchudele = timeSubTractTime(
                  scheduleStartTime,
                  scheduleEndTime
                );
                totalHourSchudele += hourSchudele || 0
                const hourHistory = isHistory
                  ? timeSubTractTime(historyStartTime, historyEndTime)
                  : "";
                const serviceType = serviceTypes.find(
                  (serviceType) =>
                    serviceType.id &&
                    schedule.nursing_care_history.service_type_id &&
                    serviceType.id ===
                      schedule.nursing_care_history.service_type_id
                );

                return (
                  <tr key={`${indexShift}-${indexShedule}`}>
                    <td className="text-center">
                      {dayjs(schedule.date).format("D")}
                    </td>
                    <td className="text-center">
                      {dayjs(schedule.date).locale("ja").format("dd")}
                    </td>
                    <td className="text-center">
                      <span className="opacity-0">0</span>
                    </td>
                    <td className="text-center">{serviceType?.detail}</td>
                    <td className="text-center">{scheduleStartTime}</td>
                    <td className="text-center">{scheduleEndTime}</td>
                    <td className="text-center">{hourSchudele}</td>

                    <td className="text-center"></td>
                    <td className="text-center" colSpan={2}>
                      {historyStartTime}
                    </td>
                    <td className="text-center" colSpan={2}>
                      {historyEndTime}
                    </td>
                    <td className="text-center">{hourHistory}</td>
                    <td className="text-center"></td>
                    <td className="text-center">1</td>  {/*添道人數*/}
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td className="text-center" colSpan={2}></td>
                  </tr>
                );
              });
          });
          return (
            <div
              className="w-[210mm] min-h-[297mm] text-[2.8mm] break-inside"
              key={client.id}
            >
              <Flex justifyContent="space-between" className="h-[8mm]">
                <div className="px-[4mm] flex  items-end text-[3.5mm]">
                  {monthSelected} 分
                </div>
                <div className="flex  items-end text-[4mm]">
                  居宅介護サービス提供実績記録票
                </div>
                <div className="px-[4mm] text-[3.5mm]">(様式1)</div>
              </Flex>
              <TablelStyled>
                <tr>
                  <td rowSpan={2}>
                    <div className="text-center">
                      受給者証 <br />番 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 号
                    </div>
                  </td>
                  {getJsxSeparate(10, recipient?.recipient_code, 2)}
                  <td rowSpan={2}>
                    <div>
                      <p className="text-center">支給決定障害者等氏名</p>
                      <p className="text-center">(障害児氏名)</p>
                    </div>
                  </td>
                  <td rowSpan={2}>
                    <div>{client.family_name}</div>
                  </td>
                  <td className="text-center">事業所番号</td>
                  {getJsxSeparate(10, profile?.business_number)}
                </tr>
                <tr>
                  <td colSpan={11} rowSpan={2} className="relative ">
                    <div className="w-full h-full absolute top-0 flex">
                      <div className="border-r-[0.5mm] p-[1mm]">
                        <p>事業者及び</p> <p>その事業所</p>
                      </div>
                      <div className="border-r-[0.5mm] flex-1 pl-[1mm] flex items-center">
                        訪問介護まるくる
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-[2mm] text-center">契約支給量</td>
                  <td colSpan={12}>{recipient?.contract_allocation_amount}</td>
                </tr>
              </TablelStyled>

              <TablelStyled className="mt-[3mm]">
                <Fragment key="header">
                  <tr>
                    <td rowSpan={3} className="text-center">
                      日 <br /> 付
                    </td>
                    <td rowSpan={3} className="text-center">
                      曜 <br />日
                    </td>
                    <td rowSpan={3}></td>

                    <td rowSpan={3} className="text-center">
                      サービス
                      <br /> 内容
                    </td>

                    <td className="text-center" colSpan={4}>
                      居宅介護計画
                    </td>
                    <td className="text-center" colSpan={4}>
                      サービス提供時間
                    </td>
                    <td className="text-center" colSpan={2}>
                      算定時間数
                    </td>

                    <td rowSpan={3}>
                      <div className="text-orientation-right flex items-center justify-center w-full">
                        添道人數
                      </div>
                    </td>
                    <td rowSpan={3}>
                      <div className="text-orientation-right flex items-center justify-center w-full">
                        初回加算
                      </div>
                    </td>
                    <td rowSpan={3}>
                      <div className="flex justify-center items-center text-center">
                        緊急時 <br />
                        対市 <br />
                        加算
                      </div>
                    </td>
                    <td rowSpan={3}>
                      <div className="flex justify-center items-center text-center">
                        福祉専門報 <br />
                        員等連携
                        <br />
                        加算
                      </div>
                    </td>
                    <td rowSpan={3}>
                      利用者 <br />
                      確認欄
                    </td>
                    <td rowSpan={3} className="text-center">
                      備考
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center" rowSpan={2}>
                      開始時間
                    </td>
                    <td className="text-center" rowSpan={2}>
                      終了時間
                    </td>

                    <td className="text-center" colSpan={2}>
                      計画時間数
                    </td>

                    <td className="text-center" rowSpan={2} colSpan={2}>
                      開始時間
                    </td>
                    <td className="text-center" rowSpan={2} colSpan={2}>
                      終了時間
                    </td>
                    <td className="text-center" rowSpan={2}>
                      時間
                    </td>
                    <td className="text-center" rowSpan={2}>
                      乗降
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">時間</td>
                    <td className="text-center">乗降</td>
                  </tr>
                </Fragment>
                <Fragment key="body">{rowSchedules}</Fragment>

                <Fragment key="footer">
                  <tr>
                    <td rowSpan={2}></td>
                    <td colSpan={5} rowSpan={2}></td>
                    <td colSpan={2} rowSpan={2} className="text-center">
                      計画
                      <br />
                      時間数計
                    </td>
                    <td colSpan={4} className="text-center">
                      内訳(適用単価別)
                    </td>
                    <td colSpan={2} rowSpan={2} className="text-center">
                      克定 <br />
                      時間数計
                    </td>
                    <DiagonalLine rowSpan={7} />
                    <DiagonalLine colSpan={3} rowSpan={2} />
                    <DiagonalLine colSpan={2} rowSpan={7} />
                  </tr>
                  <tr>
                    <td className="text-center">100%</td>
                    <td className="text-center">90%</td>
                    <td className="text-center">70%</td>
                    <td className="text-center">重訪</td>
                  </tr>
                  <tr>
                    <td rowSpan={5} className="text-orientation-right">
                      <div className="flex items-center justify-center">
                        合計
                      </div>
                    </td>
                    <td colSpan={5} className="text-center">
                      居宅における身体介護
                    </td>
                    <td className="text-center">{totalHourSchudele}</td>
                    <DiagonalLine rowSpan={4} />
                    <td></td>

                    <DiagonalLine />

                    <td></td>
                    <td></td>
                    <td></td>
                    <DiagonalLine rowSpan={4} />
                    <td rowSpan={5}></td>
                    <td rowSpan={5}></td>
                    <td rowSpan={5}></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="text-center">
                      通院介護(身体介護を伴う)
                    </td>
                    <td></td>

                    <td></td>
                    <DiagonalLine />
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td colSpan={5} className="text-center">
                      家事援助
                    </td>
                    <td></td>

                    <td></td>
                    <td></td>
                    <DiagonalLine />
                    <DiagonalLine />
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="text-center">
                      通院介護(身体介護を伴わない)
                    </td>
                    <td></td>

                    <td></td>
                    <td></td>
                    <DiagonalLine />
                    <DiagonalLine />
                    <td></td>
                  </tr>

                  <tr>
                    <td colSpan={5} className="text-center">
                      通院等乘降介助
                    </td>
                    <DiagonalLine />
                    <td></td>
                    <td></td>
                    <td></td>
                    <DiagonalLine />
                    <DiagonalLine />
                    <DiagonalLine />
                  </tr>
                </Fragment>
              </TablelStyled>
            </div>
          );
        })}
      </Styled>
    </div>
  );
};

export default forwardRef(ServiceProvisionRecord);
