import TableAdmin from "component/admin/table";
import type { ColumnsType, TableProps } from "antd/es/table";
import { ButtonSolid, ButtonOutline } from "component/button";
import { Form, Modal } from "antd";
import { useEffect, useState } from "react";
import useService from "hook/useService";
import useTable from "hook/useTable";
import axiosClient from "api/axiosClient";
import { ITabData } from "..";
import { useParams } from "react-router";
import dayjs from "dayjs";
import { JP_DATE_FORMAT, formatJapanDate } from "constant/date";
import Styles from "./styled";
interface IProps {
  onChangeTab?: (tabData: ITabData) => void;
  isActive?: boolean;
  isStaff:boolean;
}
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    return selectedRows;
  },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.warrantyPeriod === "Disabled User", // Column configuration not to be checked
      name: record.warrantyPeriod,
    }),
  columnTitle: "選択",
};

const rowSelectionStaff = {
    ...rowSelection,
    getCheckboxProps: (record: DataType) => ({
      disabled: true
    }),
};



interface DataType {
  id: number;
  key: React.Key;
  warrantyPeriod?: string;
  levelOfCare?: string;
  certificateDepartment?: string;
  insurance?: string;
  insuranceNumber?: string;
  homeCareSupport?: string;
  careManagement?: string;
}
export default function InsuranceCardList(props: IProps) {
  const { isStaff } = props
  const service = useService();
  const { id } = useParams();
  const { setData, data, form, setLoading, setTotal } = useTable<any>();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const columns: ColumnsType<DataType> = [
    {
      align: "center",
      title: "被保証適用期間",
      render: (value, record: any) =>
        `${formatJapanDate(record.start_insurance)}から${formatJapanDate(
          record.end_insurance
        )}まで`,
      ellipsis: true,
    },
    { align: "center", title: "介護度", dataIndex: "care_level" },
    {
      align: "center",
      title: "認定区分",
      dataIndex: "certification_department",
    },
    {
      align: "center",
      title: "保険者",
      dataIndex: "insurance_name",
      ellipsis: true,
      className: "min-w-[250px] max-w-[250px]",
    },
    {
      align: "center",
      title: "被保険者番号",
      dataIndex: "insurance_number",
      ellipsis: true,
      className: "min-w-[200px] max-w-[200px]",
    },
    {
      align: "center",
      title: "居宅介護支援事業所名",
      dataIndex: "home_care_support_office_name",
      ellipsis: true,
      className: "min-w-[200px] max-w-[200px]",
    },
    {
      align: "center",
      title: "居宅介護支援事業所",
      dataIndex: "home_care_office_type",
    },
    {
      align: "center",
      title: "担当ケアマネ",
      dataIndex: "responsible_policy_management",
      ellipsis: true,
      className: "min-w-[250px] max-w-[250px]",
    },
    {
      align: "center",
      title: "",
      dataIndex: "action",
      width: 200,
      ...{ ...(isStaff ? { fixed: "right" } : {}) },
      render: (_, record) => (
        <div className="flex gap-x-[6px] justify-center">
          <ButtonOutline
            className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]"
            onClick={() => {
              props.onChangeTab?.({
                activeKey: "create",
                data: { copiedId: record?.id },
              });
              localStorage.setItem("showInsuranceDetailScreen", "true");
            }}
          >
            詳細
          </ButtonOutline>
          {!isStaff && (
            <ButtonSolid
              className="!text-[12px] !leading-[20px] !px-[19px] !h-[23px]"
              onClick={() => handleOpenDeleteModal(record.id)}
            >
              削除
            </ButtonSolid>
          )}
        </div>
      ),
    },
  ];

  const handleOpenDeleteModal =  (id: number) => {
    setOpenDelete(true);
    setIdDelete(id)
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: service.INSURANCE_CARDS,
        method: "get",
        params: { ...form.getFieldsValue(), patient_id: id },
      });
      setData(res.data.serialized_insurance_cards);
      setLoading(false);
      setTotal(res.data.total_items);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (!idDelete) return;
    const res = await axiosClient({
      url: `${service.INSURANCE_CARDS}/${idDelete}`,
      method: "delete",
    });
    if (res) { 
      setOpenDelete(false);
      fetchData();
    }
  }

  useEffect(() => {
    form.resetFields();
    fetchData();
  }, [props.isActive]);

  return (
    <Form className="!text-start">
      {/* delete modal */}
      <Modal
        title="警告"
        centered
        open={openDelete}
        okText="はい"
        cancelText="いいえ"
        onOk={handleDelete}
        onCancel={() => setOpenDelete(false)}
      >
        <p>削除しますか。</p>
      </Modal>
      {!isStaff && (
        <div className="flex justify-end gap-x-[8px] mb-[19px]">
          <ButtonSolid
            hidden={!selectedId}
            className="!px-[36px] !text-[14px] !leading-[24px] !max-h-[35px]"
            onClick={() => {
              props.onChangeTab?.({
                activeKey: "create",
                data: { copiedId: selectedId },
              });
              localStorage.setItem("showInsuranceDetailScreen", "false");
            }}
          >
            コピーして登録
          </ButtonSolid>
          <ButtonSolid
            width="170px"
            className="!px-[36px] !text-[14px] !leading-[24px] !max-h-[35px]"
            onClick={() => {
              localStorage.setItem(
                "showInsuranceDetailScreen", 'false'
              )
              props.onChangeTab?.({ activeKey: "create" })
            }}
          >
            新規登録
          </ButtonSolid>
        </div>
      )}
      <Styles>
        <TableAdmin
          rowKey={(row) => row.id}
          rowSelection={{
            type: "radio",
            rowSelection,
            ...(isStaff ? rowSelectionStaff : rowSelection),
          }}
          onRow={(data) => ({ onChange: () => setSelectedId(data.id) })}
          className="!my-0"
          pagination={false}
          data={data}
          columns={columns}
        />
      </Styles>
    </Form>
  );
}
