import styled from "@emotion/styled";

export const Styles = styled.div`
  .mode-edit {
    .ant-table-cell {
      padding: 10px 16px;
    }
  }
  .ant-form-item {
    margin: 0;
  }
`;
