import BaseApi from "api/baseApi";
import { ADMIN_CARE_CATEGORY_SETTINGS } from "../apiConstant";

class CareCategorySettings extends BaseApi {
  getList<T>() {
    return this.get<T>(ADMIN_CARE_CATEGORY_SETTINGS);
  }

  update<T>(data: any) {
    return this.post<T>(ADMIN_CARE_CATEGORY_SETTINGS, data);
  }
}

export default CareCategorySettings;