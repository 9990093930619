const ROUTES = {
  HOME: "",
  ADMIN: "admin",
  STAFF: "staff",
  ROUTINES: "routines",
  CREATE: "create",
  LOGIN: "login",
  ID: ":id",
  SUB_ID: ":subId", // in case the route has 2 id params, i.e: /customer/1/history/1
  MENU: "menu",
  RECORD: "record",
  SCHEDULE: "schedule",
  SHIFT: "shift",
  CUSTOMER: "customer",
  CALENDAR: "calendar",
  PROFILE: "profile",
  REGISTER: "register",
  NOTICE: "notice",
  HISTORY: "history",
  DAILY: "daily",
  SHIFT_STAFF: "shift_staff",
  SHIFT_CLIENT: "shift_client",
  SHIFT_MANAGEMENT: "shift-management",
  SHIFT_HOLIDAY: "shift_holiday",
  NURSING_CARE: "nursing-care-record",
  CUSTOMERDETAIL: "customer-detail",
  CARERECORD: "care-record",
  BASICINFO: "basic-information",
  CAREPLAN: "care-plan",
  CAREPLANDISABILITY: "care-plan-disability",
  EXPENSE: "expense",
  CREATEEXPENSE: "expense/create",
  INSURANCE_CARD: "insurance-card",
  CREATEINSURANCECARD: "insurance-card/create",
  RECORDDAILYADMIN: "record-daily-admin",
  HISTORY_CUSTOMER: "record-history-customer",
  CREATE_RECORD_DAILY: "create-record-daily",
  SEARCH: "search",
  EDIT: "edit",
  EDIT_HISTORY: "edit-history",
  RECORDBYDAY: "record-by-day",
  RECORDHISTORYDAILY: "record-history-daily",
  VISITSCHEDULE: "visit-schedule",
  MONTHSHIFTBEFORE: "month-shift-before",
  MONTHSHIFTAFTER: "month-shift-after",
  BULLETINBOARD: "bulletin-board",
  CREATE_BULLETINBOARD: "bulletin-board/create",
  BULLETINBOARDPARENTTHREAD: "bulletin-board-parent-thread",
  STAFF_POST_EDIT: "bulletin-board-parent-thread/:postId",
  STAFF_POST_ADD: "bulletin-board-parent-thread",
  BULLETINBOARDCONTENT: "bulletin-board-content/:postId",
  BULLETINBOARDCHILDRENTHREAD: "bulletin-board-children-thread",
  INDIVIDUALCONTACT: "individual-contact",
  CONTACT_LIST: "contact-list",
  CONTACT_CHAT: "contact-chat/:id",
  COMMENT_DETAIL: "comment-detail/:id",
  POST_DETAIL: "post-detail/:id",
  HISTORYCUSTOMERNAME: "history-customer-name",
  HISTORYCUSTOMERLIST: "history-customer-list",
  MANAGE_SHIFT: "manage-shift",
  MANAGE_CUSTOMER: "manage-customer",
  EDIT_STAFF: "edit-staff",
  MENU_PROFILE: "menu-profile",
  MENU_SHIFT_REGISTRATION_LIST: "menu-shift",
  MENU_SHIFT_REGISTRATION_DETAIL: "menu-shift/:id",
  MENU_SHIFT_REGISTRATION_ADD: "menu-shift/add",
  MENU_SHIFT_REGISTRATION_EDIT: "menu-shift/edit/:id",
  CARE_SERVICE: "care-service",
  FACILITY_REGISTRATION_LIST: "facility",
  FACILITY_REGISTRATION_DETAIL: "facility/:id",
  FACILITY_REGISTRATION_ADD: "facility/registration",
  FACILITY_REGISTRATION_EDIT: "facility/edit/:id",
  HOME_SYSTEM: "home-system-admin",
  HOME_DASHBOARD: "dashboard",
  HOME_CUSTOMERS: "customers",
  HOME_SCHEDULES: "schedules",
  HOME_NURSING_CARE: "nursing-care",
  HOME_SYSTEM_STAFF: "home-system-staff",
  HOME_EQUIPMENT_SERVICES: "equipment-services",
  
  PAYMENT: "payment",
  USAGE: "usage",
  RECEIPT: "receipt",
  BILLING: "billing",
  RENT: "rent",
  DETAIL: "detail",

  SHIFT_MANAGEMENT_STAFF: "shift-management-staff",
  CARE_CATEGORY_SETTINGS: "care_category_settings"
};

export default ROUTES;
