import { DatePicker, Form, Input, Radio, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { ButtonOutline, ButtonSolid } from "component/button";
import Styles from "./styled";
import useService from "hook/useService";
import { useParams } from "react-router-dom";
import MESSAGES from "constant/messages";
import axiosClient from "api/axiosClient";
import { DATE_FORMAT_2, JP_DATEPICKER_FORMAT } from "constant/date";
import { ITabData } from "..";
import React, { useState } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import NumericInput from "component/NumberInput";
import REGEX from "constant/regex";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";
import SelectWithData from "component/SelectWithData";
import { IDistrict } from "constant/interfaces";
interface IProps {
  onChangeTab?: (tabData: ITabData) => void;
  isActive?: boolean;
  copiedId?: number;
  isStaff: boolean;
}

export default function CreateInsuranceCard(props: IProps) {
  const { isStaff } = props;
  const [form] = Form.useForm();
  const service = useService();
  const { id } = useParams();
  const district = Form.useWatch("district", form);
  const city = Form.useWatch("city", form);
  const [isDisabled, setIsDisabled] = useState(false);
  const home_care_office_type = Form.useWatch("home_care_office_type", form);
  const certification_department = Form.useWatch(
    "certification_department",
    form
  );
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (formData: any) => {
    setSubmitting(true);
    const isShowInsuranceDetailScreen = localStorage.getItem(
      "showInsuranceDetailScreen"
    );

    try {
      formData.patient_id = id;
      formData.start_insurance =
        formData.insurance_time[0].format(DATE_FORMAT_2);
      formData.end_insurance = formData.insurance_time[1].format(DATE_FORMAT_2);
      formData.start_validate = formData.validate_time[0].format(DATE_FORMAT_2);
      formData.end_validate = formData.validate_time[1].format(DATE_FORMAT_2);
      formData.release_date = formData.release_date?.format(DATE_FORMAT_2);
      formData.certification_date =
        formData.certification_date?.format(DATE_FORMAT_2);
      formData.start_date_apply_benefits =
        formData.start_date_apply_benefits?.format(DATE_FORMAT_2);
      formData.care_application_start_date =
        formData.care_application_start_date?.format(DATE_FORMAT_2);
      delete formData.validate_time;
      delete formData.insurance_time;
      const res = await axiosClient({
        method:
          isShowInsuranceDetailScreen === "false" ||
          !isShowInsuranceDetailScreen
            ? "post"
            : "put",
        url:
          isShowInsuranceDetailScreen === "false" ||
          !isShowInsuranceDetailScreen
            ? service.INSURANCE_CARDS
            : `${service.INSURANCE_CARDS}/${props.copiedId}`,
        data: formData,
      });
      props.onChangeTab?.({ activeKey: "list" });
      toast.success(res.data.success);
    } catch (error) {
      toast.error((error as string).toString());
    } finally {
      setSubmitting(false);
    }
  };
  const fetchInitialData = async () => {
    try {
      const { data } = await axiosClient.get(
        service.INSURANCE_CARDS + "/" + props.copiedId
      );
      form.setFieldsValue({
        ...data,
        insurance_time: [
          dayjs(data.start_insurance),
          dayjs(data.end_insurance),
        ],
        validate_time: [dayjs(data.start_validate), dayjs(data.end_validate)],
        start_date_apply_benefits: dayjs(data.start_date_apply_benefits),
        care_application_start_date: dayjs(data.care_application_start_date),
        announcement_date:
          data.announcement_date && dayjs(data.announcement_date),
        release_date: data.release_date && dayjs(data.release_date),
        certification_date:
          data.certification_date && dayjs(data.certification_date),
      });
      localStorage.getItem("showInsuranceDetailScreen") === "false"
        ? setIsDisabled(false)
        : setIsDisabled(true);
    } catch (error) {}
  };

  const getAdressByZipcode = async (zipcode: string) => {
    try {
      if (zipcode.replace("_", "").length === 8) {
        const { data } = await axiosClient.get(service.INFO_ADDRESS_BY_CODE, {
          params: {
            code: zipcode,
          },
        });
        form.setFieldValue("district", data.info_address.district.name);
        form.setFieldValue("city", data.info_address.city.name);
        form.setFieldValue("street", data.info_address.address.name);
      }
    } catch (error) {}
  };

  const getDistrictOptions = async () =>
    (await axiosClient.get(service.DISTRICTS)).data.districts.map(
      (item: IDistrict) => ({ label: item.name, value: item.name })
    );

  const getCityOptions = async () =>
    (
      await axiosClient.get(service.CITIES, {
        params: { district_name: district },
      })
    ).data.cities.map((item: IDistrict) => ({
      label: item.name,
      value: item.name,
    }));

  const getAddressOptions = async () =>
    (
      await axiosClient.get(service.ADDRESSES, {
        params: { city_name: city, district_name: district },
      })
    ).data.addresses.map((item: IDistrict) => ({
      label: item.name,
      value: item.name,
    }));

  const getZipCodeByAddress = async (address: string) => {
    if (city && district && address) {
      const { data } = await axiosClient.get(service.POST_CODE, {
        params: {
          district_name: district,
          city_name: city,
          address_name: address,
        },
      });
      form.setFieldValue("zipcode", data.post_code);
    }
  };

  React.useEffect(() => {
    form.resetFields();
    if (props.copiedId && props.isActive) fetchInitialData();
  }, [props]);
  return (
    <Styles className="text-left">
      {isDisabled && !isStaff ? (
        <div className="flex justify-end">
          <ButtonSolid
            className="!w-[137px]"
            onClick={() => setIsDisabled(false)}
          >
            編集
          </ButtonSolid>
        </div>
      ) : (
        ""
      )}
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ flex: "110px" }}
        labelWrap
      >
        <div className="flex justify-between items-end mb-2">
          <div className="text-[20px] font-semibold">■被保険者証情報</div>
          {/* <ButtonSolid width={137} height="35px" fontSize={14}>
            編集
          </ButtonSolid> */}
        </div>
        <div className="border-t border-solid border-black max-w-[700px] p-[14px_14px_45px_0]">
          <Form.Item
            label="被保証適用期間"
            name="insurance_time"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <DatePicker.RangePicker
              className="w-full"
              format={JP_DATEPICKER_FORMAT}
              disabled={isDisabled}
            />
          </Form.Item>
          <Form.Item
            label="保険者番号"
            name="insurance_company_number"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <NumericInput maxLength={50} disabled={isDisabled} />
          </Form.Item>
          <Form.Item
            label="保険者名"
            name="insurance_name"
            rules={[
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
              {
                pattern: REGEX.onlyTextAndNumber,
                message: MESSAGES.invalidCharacter,
              },
            ]}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
          <Form.Item
            label="認定区分"
            name="certification_department"
            initialValue="certified"
          >
            <Radio.Group
              options={[
                { label: "認定済", value: "certified" },
                { label: "申請中", value: "pending" },
              ]}
              disabled={isDisabled}
            />
          </Form.Item>
          {certification_department === "certified" && (
            <Form.Item
              label="被保険者番号"
              name="insurance_number"
              rules={[
                {
                  required: true,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <NumericInput maxLength={255} disabled={isDisabled} />
            </Form.Item>
          )}
          <Form.Item label="交付年月日" name="release_date">
            <DatePicker
              className="w-full"
              format={JP_DATEPICKER_FORMAT}
              disabled={isDisabled}
            />
          </Form.Item>
          <Form.Item label="認定年月日" name="certification_date">
            <DatePicker
              className="w-full"
              format={JP_DATEPICKER_FORMAT}
              disabled={isDisabled}
            />
          </Form.Item>
          <Form.Item
            label="認定有効期間"
            name="validate_time"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <DatePicker.RangePicker
              className="w-full"
              format={JP_DATEPICKER_FORMAT}
              disabled={isDisabled}
            />
          </Form.Item>
          <Form.Item
            label="要介護度"
            name="care_level"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <Select
              options={[...Array(5)].map((i, index) => ({
                label: `要介護度${index + 1}`,
                value: `要介護度${index + 1}`,
              }))}
              disabled={isDisabled}
            />
          </Form.Item>
        </div>
        <div className="h-[16px] bg-[var(--secondary-color)] m-[0_-23px]" />
        <div>
          <div className="text-[20px] font-semibold mt-[26px]">
            ■居宅介護支援事業所
          </div>
          <div className="border-t border-solid border-black max-w-[700px] p-[14px_14px_45px_0]">
            <Form.Item
              label="認定年月日"
              name="care_application_start_date"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <DatePicker
                className="w-full"
                format={JP_DATEPICKER_FORMAT}
                disabled={isDisabled}
              />
            </Form.Item>
            <Form.Item
              label="居宅介護支援事業所名"
              name="home_care_support_office_name"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
              ]}
            >
              <Input className="w-full" disabled={isDisabled} />
            </Form.Item>
            <Form.Item
              label="居宅介護支援事業所"
              name="home_care_office_type"
              rules={[{ required: true, message: MESSAGES.requireField }]}
              initialValue="own_office"
            >
              <Radio.Group disabled={isDisabled}>
                <Radio value="own_office" className="whitespace-nowrap">
                  自社事業所
                </Radio>
                <Radio value="other_office" className="whitespace-nowrap">
                  他社事業所
                </Radio>
                <Radio
                  value="self_created_or_not_declared"
                  className="whitespace-nowrap"
                >
                  自己作成または届け出なし
                </Radio>
              </Radio.Group>
            </Form.Item>

            {home_care_office_type === "other_office" && (
              <>
                {/*<Form.Item name="home_care_office_input_second" label="">
                  <Input disabled={isDisabled} />
                </Form.Item>*/}

                <Form.Item
                  label="郵便番号"
                  name="zipcode"
                  rules={[
                    { len: 8 },
                    { required: true, message: MESSAGES.requireField },
                    {
                      pattern: REGEX.zipcode,
                      message: MESSAGES.invalidPostCode,
                    },
                  ]}
                >
                  <PatternFormat
                    customInput={Input}
                    format="###-####"
                    mask="_"
                    onChange={(e) => getAdressByZipcode(e.target.value)}
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  name="district"
                  label="都道府県"
                  rules={[{ required: true, message: MESSAGES.requireField }]}
                >
                  <SelectWithData
                    onChange={() => {
                      form.setFieldValue("city", undefined);
                      form.setFieldValue("street", undefined);
                    }}
                    getOptions={getDistrictOptions}
                    placeholder="選択してください"
                    disabled={isDisabled}
                  />
                </Form.Item>

                <Form.Item
                  label="市区町村"
                  name="city"
                  rules={[{ required: true, message: MESSAGES.requireField }]}
                >
                  <Input
                    disabled={!district || isDisabled}
                    placeholder="選択してください"
                  />
                </Form.Item>

                <Form.Item
                  label="町名・番地"
                  name="street"
                  rules={[{ required: true, message: MESSAGES.requireField }]}
                >
                  <Input
                    disabled={!district || !city || isDisabled}
                    placeholder="選択してください"
                  />
                </Form.Item>
              </>
            )}

            {(home_care_office_type === "own_office" ||
              home_care_office_type === "other_office") && (
              <>
                {/*<Form.Item name="home_care_office_input_first" label=" ">
                  <Input disabled={isDisabled} />
                </Form.Item>*/}
                <Form.Item
                  label="担当ケアマネージャー"
                  name="responsible_policy_management"
                  rules={[
                    { required: true, message: MESSAGES.requireField },
                    {
                      pattern: REGEX.atLeastOneNonSpaceCharacter,
                      message: MESSAGES.requireField,
                    },
                  ]}
                >
                  <Input disabled={isDisabled} className="w-[50%]" />
                </Form.Item>
              </>
            )}

            <Form.Item
              label="地方包括支援センター"
              name="comprehensive_support_center"
            >
              <Input disabled={isDisabled} />
            </Form.Item>
            {home_care_office_type === "own_office" && (
              <Form.Item
                label="届出年月日"
                name="announcement_date"
                rules={[{ required: true, message: MESSAGES.requireField }]}
              >
                <DatePicker
                  className="w-full"
                  format={JP_DATEPICKER_FORMAT}
                  disabled={isDisabled}
                />
              </Form.Item>
            )}
          </div>
          <div className="h-[16px] bg-[var(--secondary-color)] m-[0_-23px]" />
          <div>
            <div className="text-[20px] font-semibold mt-[26px]">■給付制限</div>
            <div className="border-t border-solid border-black max-w-[700px] p-[14px_14px_45px_0]">
              <Form.Item
                label="適用開始年月"
                name="start_date_apply_benefits"
                rules={[{ required: true, message: MESSAGES.requireField }]}
              >
                <DatePicker
                  className="w-full"
                  format={JP_DATEPICKER_FORMAT}
                  disabled={isDisabled}
                />
              </Form.Item>
              <Form.Item
                name="benefit_limit"
                label="給付率"
                rules={[{ required: true, message: MESSAGES.requireField }]}
              >
                <NumericFormat
                  allowNegative={false}
                  customInput={Input}
                  className="w-[100px]"
                  addonAfter="%"
                  isAllowed={({ floatValue, value }) =>
                    Boolean(
                      (floatValue !== undefined &&
                        floatValue <= 100 &&
                        floatValue >= 0) ||
                        value === ""
                    )
                  }
                  disabled={isDisabled}
                />
              </Form.Item>
            </div>
          </div>

          {isStaff && (
            <div className="text-right">
              <ButtonOutline
                className="mr-[8px]"
                onClick={() => {
                  props.onChangeTab?.({ activeKey: "list" });
                  setIsDisabled(false);
                }}
              >
                キャンセル
              </ButtonOutline>
            </div>
          )}

          {!isDisabled && (
            <div className="text-right">
              <ButtonOutline
                className="mr-[8px]"
                onClick={() => {
                  props.onChangeTab?.({ activeKey: "list" });
                  setIsDisabled(false);
                }}
              >
                キャンセル
              </ButtonOutline>
              <ButtonSolid
                width={137}
                onClick={form.submit}
                isLoading={submitting}
              >
                保存
              </ButtonSolid>
            </div>
          )}
        </div>
      </Form>
    </Styles>
  );
}
