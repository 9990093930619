import React  from "react";
import { Input, InputProps,  } from "antd";
import { formatNumber, formatNumberFullWidthToHalfWidth } from 'utils/formats'

interface IProps extends InputProps {
  type?: "math" | "text";
  maxLen? : number
  onChange?: (value: any) => void;
}
const NumberInputWithFormat: React.FC<IProps> = ({onChange, ...restProps}: IProps) => {
  const defaultValue = restProps.type === "math" ? "0" : ""
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let numericValue = inputValue.replace(/[^0-9０-９]/g, "")
    if(restProps.type === "math") {
      numericValue = numericValue.replace(/^0+/, "") || "0"
    }
    if(restProps?.maxLen && (numericValue.length > restProps.maxLen))  return
    onChange && onChange(numericValue)
  };
  const handleBlur = () => {
    onChange?.(formatNumber(formatNumberFullWidthToHalfWidth(restProps?.value as string || defaultValue)));
  };

  return (
    <Input
      {...restProps}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default NumberInputWithFormat;
