export const formatNumber = (val: string) => {
  return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const unFormat = (val: string) => {
  return val.replaceAll(",", "");
};

export const formatNumberFullWidthToHalfWidth = (str: string): string =>
  str.toString().replace(/[０-９]/g, (char) => 
      String.fromCharCode(char.charCodeAt(0) - 0xFEE0)
  );

export const extractNumbers = (str: string): string => {
   // Convert full-width numbers (e.g. '５') to half-width (e.g. '5')

  // Assuming you already have a logic to extract numbers after the conversion
  return str.toString().replaceAll(" ", "").replace(/[^０-９.-] | [^0-9.-]]/g, ''); // Adjust regex based on your needs
}
export function formatPhoneNumber(phone: string) {
  // Ensure the phone number length is between 9 and 12
  if (phone.length < 9 || phone.length > 12) {
    return phone; 
  }

  if (phone.length === 9) {
    return phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
  } else if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  } else if (phone.length === 11) {
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (phone.length === 12) {
    return phone.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1-$2-$3-$4');
  }

  return phone; 
}

export function roundToDecimal(number: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}

