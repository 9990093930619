import { Checkbox, DatePicker, Form, Input, Modal, Select } from "antd";
import { useState, useMemo, useEffect } from "react";
import { MinusCircle, PlusCircle, CalendarBlank } from "assets/icons";
import ModalStyled from "./styled";
import { SelectedItem, TypeDataSubmit, ShiftType } from "../../type";
import dayjs, { Dayjs } from "dayjs";
import { SHIFT_TYPES } from "constant";
import { DATE_FORMAT_2 } from "constant/date";
import { uniqueId } from "lodash";

interface IProps {
  open: boolean;
  onCancel: () => void;
  selectedItems: SelectedItem[];
  handleSaveTmpDataSubmit: (dateFormats: string[], nurseId: string, shiftType: ShiftType, isDelete: boolean, note?: string | null ) => void
  isStaff: boolean,
  nameNurseSelecting: string
}


const MolalSchedule = (props: IProps) => {
  const { open, onCancel, selectedItems, handleSaveTmpDataSubmit, nameNurseSelecting, isStaff } = props;
  const [dataDate, setDataDate] = useState<SelectedItem[]>(selectedItems);
  const [form] = Form.useForm();
  const handleOnchangeDate = (date: Dayjs | null, index: number) => {
    if (dataDate[index]) {
      const dataDateClone = [...dataDate];
      dataDateClone[index].date = date || null;
      setDataDate(dataDateClone);
    }
  };

  const handleAdd = (index: number) => {
    const firstPartData = dataDate.slice(0, index + 1)
    const lastPartData = dataDate.slice(index + 1, dataDate.length)
    setDataDate([
      ...firstPartData,
      {
        date: null,
        indexCol: selectedItems[0].indexCol,
        nurseId: selectedItems[0].nurseId,
        key: uniqueId("at_modal_")
      },
      ...lastPartData
    ]);
  };

  const handleRemove = (key: string) => {
    if (dataDate.length === 1) return;
    const newDataDate = dataDate.filter(item => item.key !== key);
    setDataDate(newDataDate);
  };
  useEffect(() => {
    if(selectedItems.length === 1) {
       selectedItems[0].shiftType && form.setFieldValue("shift_type", selectedItems[0].shiftType)
       selectedItems[0].note && form.setFieldValue("note", selectedItems[0].note)
    }
    setDataDate(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    const dateValues = form.getFieldsValue()?.dates || {}
    form.setFieldsValue({
      dates: dataDate.reduce((acc : {[key: string]: Dayjs}, cur) => {
        const keyAs = cur.key as keyof typeof acc;
        if (dateValues[keyAs] || cur.date) acc[keyAs] =  dateValues[keyAs] ? dayjs(dateValues[keyAs]) : dayjs(cur.date)
        return acc
      }, {}), nurse_id: selectedItems[0]?.nurseId
    });
  }, [dataDate]);

  const disabledDates = useMemo(
    () => dataDate.map((item) => dayjs(item.date)),
    [dataDate]
  );

  const disableSpecificDates = (current: dayjs.Dayjs) =>
    disabledDates.some((date) => date.isSame(current, "day"));
  const optionShiftType = Object.values(SHIFT_TYPES);

  const handleSubmit = () => {
    // submit to save on client not BE
    const values = form.getFieldsValue() 
    const dateFormats = Object.values(values.dates)?.map((date: any) => {
      const dateAs = date as Dayjs
      return dateAs.format(DATE_FORMAT_2)
    })

    const nurseId = values.nurse_id
    const shiftType = values.shift_type as ShiftType
    const isDelete = values.isDelete
    const note = values.note
    handleSaveTmpDataSubmit(dateFormats, nurseId, shiftType, isDelete, note)
    form.resetFields()
    onCancel() //turn off popup
  }

  return (
    <ModalStyled
      open={open}
      okText="保存"
      title={<div>
        <div className="text-sm text-[#010A02] !pr-1">休日・出勤調整</div>
        <div className="text-sm text-[#010A02] !pr-1 truncate">{nameNurseSelecting}</div>
      </div>}
      okButtonProps={{ className: "w-[60px] h-9 mr-2" }}
      className="!w-[389px]"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => form.submit()}
      centered
    >
      <Form
        layout={"vertical"}
        form={form}
        onFinish={handleSubmit}
        labelWrap
        className="max-h-[calc(100vh-150px)] overflow-y-auto pr-2"
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
        }}
      >
        {dataDate.map((item, index) => (
          <div className="flex" key={item.key}>
            <Form.Item
              label={index === 0 ? "日にち" : ""}
              name={["dates", item.key]}
              className="flex-1"
              rules={[{ required: true, message: "必須項目です。" }]}
              validateTrigger="submit"
            >
              <DatePicker
                placeholder="選択してください"
                className="w-full !border-[#00000026] pr-8"
                format={DATE_FORMAT_2}
                onChange={(date) => {
                  handleOnchangeDate(date, index);
                }}
                suffixIcon={
                  <div className="text-[#010A02] -mr-7">
                    <CalendarBlank />
                  </div>
                }
                disabledDate={disableSpecificDates}
              />
            </Form.Item>

            <div className={`flex ml-3 h-8 items-center text-[#E07B2E] ${index !== 0 ? "" : "mt-[30px]"}`}>
              <div className=" cursor-pointer mr-1" onClick={() => handleAdd(index)}>
                <PlusCircle />
              </div>
              <div
                className={`${
                  dataDate.length === 1
                    ? "cursor-not-allowed opacity-40"
                    : "cursor-pointer"
                } `}
                onClick={() => handleRemove(item.key)}
              >
                <MinusCircle />
              </div>
            </div>
          </div>
        ))}
        <Form.Item
          label={"対応シフト"}
          name={"shift_type"}
          className="flex-1"
          rules={[{ required: true, message: "必須項目です。" }]}
        >
          <Select
            placeholder="対応シフトを選択してください"
            className="w-full !border-[#00000026]"
            options={optionShiftType}
          />
        </Form.Item>
        <Form.Item
          label={"備考欄"}
          name={"note"}
          className="flex-1"
        >
          <Input/>
        </Form.Item>
        <Form.Item name={"isDelete"} style={{ margin: 0 }}>
          <Checkbox
            onChange={(e) => {
              form.setFieldValue("isDelete", e.target.checked);
            }}
          ></Checkbox>
          削除
        </Form.Item>
      </Form>
    </ModalStyled>
  );
};

export default MolalSchedule;