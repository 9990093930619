import { Col, Form, Row } from "antd";
import apiFactory from "api";
import Input from "component/admin/input";
import { ButtonOutline, ButtonSolid } from "component/button";
import NumericInput from "component/NumberInput";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function ProfileDetail() {
  const [disabled, setDisable] = useState(true)
  const [submitting , setSubmitting] = useState(false)
  const [form] = Form.useForm();
  const [data, setData] = useState<{
    id: number | null;
    facility_name: string;
    zipcode: string;
    district: string;
    city: string;
    street: string;
    building_name: string;
    mobile_phone: string;
    fax: string;
    business_number: string;
    grade_registration: string;
    rate_registration: string;
  }>({
    id: null,
    facility_name: "",
    zipcode: "",
    district: "",
    city: "",
    street: "",
    building_name: "",
    mobile_phone: "",
    fax: "",
    business_number: "",
    grade_registration: "",
    rate_registration: "",
  });

  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      await apiFactory.profileAdminApi.updateProfile(values);
      setDisable(true);
      toast.success("更新に成功しました。");
      setData(values)
    } catch (err) {
      toast.error("保存が失敗しました。");
    } finally {
      setSubmitting(false);
    }
  };

  const fetchData = async () => {
    try {
      const result = await apiFactory.profileAdminApi.getServiceDetail()
      if (result) {
        const data = {
          id: result.id,
          facility_name: result.facility_name,
          zipcode: result.zipcode,
          district: result.district,
          city: result.city,
          street: result.street,
          building_name: result.building_name,
          mobile_phone: result.mobile_phone,
          fax: result.fax,
          business_number: result.business_number,
          grade_registration: result.grade_registration,
          rate_registration: result.rate_registration,
        }
        form.setFieldsValue(data);
        setData(data)
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  return (
    <div>
      <div className="page-title">管理メニュー　プロフィール</div>
      <div className="page-container">
        {disabled && (
          <div className="text-end mb-[30px]">
            <ButtonSolid
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
              onClick={() => setDisable(!disabled)}
            >
              編集
            </ButtonSolid>
          </div>
        )}
        <Form
          initialValues={data}
          onFinish={onFinish}
          form={form}
          disabled={disabled}
        >
          <Row>
            <Col span={11}>
              <Form.Item label="施設ID" name="id">
                <Input disabled/>
              </Form.Item>
              <Form.Item label="施設名" name="facility_name">
                <Input />
              </Form.Item>
              <Form.Item label="郵便番号" name="zipcode">
                <Input />
              </Form.Item>
              <Form.Item label="都道府県" name="district">
                <Input />
              </Form.Item>
              <Form.Item label="市区町村" name="city">
                <Input />
              </Form.Item>
              <Form.Item label="町名・番地" name="street">
                <Input />
              </Form.Item>
              <Form.Item label="ビル・マンション名" name="building_name">
                <Input />
              </Form.Item>
              <Form.Item label="電話番号" name="mobile_phone">
                <Input />
              </Form.Item>
              <Form.Item label="FAX" name="fax">
                <Input />
              </Form.Item>
              <Form.Item label="事業者番号" name="business_number">
                <Input />
              </Form.Item>
              <Form.Item label="等級登録" name="grade_registration">
                <Input />
              </Form.Item>
              <Form.Item label="掛け率登録" name="rate_registration">
                <NumericInput />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {!disabled && (
          <div className="flex items-center justify-between">
            <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
            <div className="flex gap-x-[8px]">
              <ButtonOutline
                className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                onClick={() => {
                  setDisable(true)
                  form.setFieldsValue(data)
                }}
              >
                キャンセル
              </ButtonOutline>
              <ButtonSolid
                className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
                onClick={form.submit}
                isLoading={submitting}
              >
                保存
              </ButtonSolid>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
