import { ROLE } from "constant";
import ROUTES from "constant/routes";
import useAuthenticate from "hook/useAuthenticate";
import AdminLayout from "layouts/AdminLayout";
import ClientLayout from "layouts/ClientLayout";
import HomeSystemLayout from "layouts/HomeSystemLayout";
import StaffLayout from "layouts/StaffLayout";
import CustomerChangeHistory from "page/Customer/ChangeHistory";
import CustomerDetail from "page/Customer/CustomerDetail";
import CreateRecord from "page/Customer/CustomerDetail/CreateRecord";
import CustomerHistoryDetail from "page/Customer/CustomerHistory/Detail";
import CustomerHistoryList from "page/Customer/CustomerHistory/List";
import CustomerList from "page/Customer/CustomerList";
import HistoryRecordDaily from "page/HistoryRecordDaily";
import LoginPage from "page/Login";
import MenuClientPage from "page/MenuClientPage";
import NotFound from "page/NotFound";
import RecordDailyMaster from "page/RecordDailyMaster";
import ShiftPage from "page/Shift";
import ShiftManagement from "page/Shift/ShiftManagement";
import DashBoardAdmin from "page/admin";
import CareService from "page/admin/Menu/CareService/CareService";
import FacilityDetail from "page/admin/Menu/Facility/FacilityDetail";
import FacilityList from "page/admin/Menu/Facility/FacilityList";
import ProfileDetail from "page/admin/Menu/Profile/ProfileDetail";
import SettingNursingCareLevel from "page/admin/Menu/SettingNursingCareLevel";

import ShiftRegistrationDetail from "page/admin/Menu/ShiftRegistration/ShiftRegistrationDetail";
import CommentDetailView from "page/admin/Notice/Board/CommentDetail/CommentDetailView";
import { PostDetailView } from "page/admin/Notice/Board/PostDetail/PostDetailView";
import { PostDetailView as HSAdminPostDetailView } from "page/admin/HomeSystem/Notice/Board/PostDetail/PostDetailView";
import ContactChatView from "page/admin/Notice/Communication/ContactChat/ContactChatView";
import Notice from "page/admin/Notice/Notice";
import CareplanBilling, {
  TPatientType,
} from "page/admin/Payment/Detail/Billing/Careplan";
import RentBilling from "page/admin/Payment/Detail/Billing/Rent";
import ReceiptRegister from "page/admin/Payment/Detail/Receipt";
import UsageHistory, { TUsage } from "page/admin/Payment/Detail/Usage";
import PaymentPage from "page/admin/Payment/List";
import RecordEachCustomer from "page/admin/RecordEachCustomer";
import StaffChangeHistory from "page/admin/Staff/StaffChangeHistory";
import StaffDetail from "page/admin/Staff/StaffDetail";
import StaffList from "page/admin/Staff/StaffList";
import ClientVisitSchedulePage from "page/client/ClientVisitSchedulePage";
import ClientHistoryList from "page/client/RecordHistory/ClientHistoryList";
import RecordHistory from "page/client/RecordHistory/RecordHistory";
import SearchPage from "page/client/SearchPage";
import HomeCustomerDetail from "page/homeSystem/Customers/CustomerDetail";
import HomeCustomers from "page/homeSystem/Customers/CustomerList";
import { default as HomeSystemDashboard } from "page/homeSystem/Dashboard";
import { default as HSAdminDashboard } from "page/admin/HomeSystem/Dashboard";
import HomeNursingCareDetail from "page/homeSystem/NursingCare/NursingCareDetail";
import HomeNursingCareList from "page/homeSystem/NursingCare/NursingCareList";
import HomeSchedule from "page/homeSystem/Schedules";
import HomeServiceDeviceList from "page/homeSystem/ServiceDeviceList";
import NextMonthShiftAfter from "page/staff/NextMonthShiftAfter";
import NextMonthShiftBefore from "page/staff/NextMonthShiftBefore";
import { CommentDetail } from "page/staff/Notice/Board/CommentDetail";
import { PostDetail } from "page/staff/Notice/Board/PostDetail";
import { PostList } from "page/staff/Notice/Board/PostList";
import { ContactChat } from "page/staff/Notice/Contact/ContactChat";
import { ContactList } from "page/staff/Notice/Contact/ContactList";
import VisitSchedulePage from "page/staff/VisitSchedulePage";
import HistoryCustomerList from "page/staff/custormer/HistoryCustomerList";
import HistoryCustomerNameList from "page/staff/custormer/HistoryCustomerNameList";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  Navigate,
  Outlet,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { CLIENTPATH } from "route";
import { RootState } from "store";
import ProtectedRoutes from "./ProtectRoute";
import PublicRoute from "./PublicRoute";
import CreateNoti from "page/admin/Notice/create";
import CreatePost from "page/staff/Notice/Board/CreatePost";
import HSAdminCreateNoti from "page/admin/HomeSystem/Notice/CreateNotice";
import HSAdminNotice from "page/admin/HomeSystem/Notice";
import DashBoardStaff from "page/staff/DashBoardStaff";
import HSAdminCommentDetailView from "page/admin/HomeSystem/Notice/Board/CommentDetail/CommentDetailView";
import HSAdminContactChatView from "page/admin/HomeSystem/Notice/Communication/ContactChat/ContactChatView";

import StaffNursingCareRecord from "page/staff/NursingCareRecord";
import StaffRecordHistoryCustomer from "page/staff/NursingCareRecord/RecordHistoryCustomer";
import StaffCreateRecordDaily from "page/staff/NursingCareRecord/CreateRecordDaily";

import HSStaffCreateNoti from "page/homeSystem/staff/Notice/CreateNotice";
import HSStaffNotice from "page/homeSystem/staff/Notice";
import HSStaffCommentDetailView from "page/homeSystem/staff/Notice/Board/CommentDetail/CommentDetailView";
import HSStaffContactChatView from "page/homeSystem/staff/Notice/Communication/ContactChat/ContactChatView";
import AdminNursingCareRecord from "page/admin/NursingCareRecord";
import DailyFormPage from "page/Shift/DailyFormPage";
import NursingCareDetail from "page/staff/NursingCareRecord/NursingCareDetail";
import NursingCareRecordDetail from "page/admin/NursingCareRecordDetail";
import ManageShiftStaff from "page/ManageShiftStaff";

//--- Route all role
const routesForAllRole: RouteObject[] = [
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: `${ROUTES.ADMIN}/${ROUTES.LOGIN}`,
    element: (
      <PublicRoute>
        <LoginPage typeLogin={ROLE.ADMIN} />
      </PublicRoute>
    ),
  },
  {
    path: `/${ROUTES.STAFF}/${ROUTES.LOGIN}`,
    element: (
      <PublicRoute>
        <LoginPage typeLogin={ROLE.STAFF} />
      </PublicRoute>
    ),
  },
  {
    path: `${ROUTES.LOGIN}`,
    element: (
      <PublicRoute>
        <LoginPage typeLogin={ROLE.CLIENT} />
      </PublicRoute>
    ),
  },
  {
    path: `${ROUTES.HOME_SYSTEM}/${ROUTES.LOGIN}`,
    element: (
      <PublicRoute>
        <LoginPage typeLogin={ROLE.HS_ADMIN} />
      </PublicRoute>
    ),
  },
  {
    path: `${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.LOGIN}`,
    element: (
      <PublicRoute>
        <LoginPage typeLogin={ROLE.HS_STAFF} />
      </PublicRoute>
    ),
  },
];

//--- Admin router
const adminRoute: RouteObject[] = [
  {
    path: ROUTES.ADMIN,
    element: (
      <ProtectedRoutes redirectTo={`/${ROUTES.ADMIN}/${ROUTES.LOGIN}`}>
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      </ProtectedRoutes>
    ),
    handle: {
      breadcrumb: () => {
        if (
          window?.location?.pathname?.includes(
            `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}`
          )
        ) {
          return null;
        }

        return <Link to={`/${ROUTES.ADMIN}`}>ホーム</Link>;
      },
    },

    children: [
      {
        path: "",
        element: <DashBoardAdmin />,
      },
      {
        path: ROUTES.NOTICE,
        handle: {
          breadcrumb: () => <Link to={ROUTES.NOTICE}>お知らせ</Link>,
        },
        children: [
          {
            path: "",
            element: <Notice />,
          },
          {
            path: ROUTES.CREATE,
            element: <CreateNoti />,
            // handle: {
            //   breadcrumb: (data: any) => (
            //     <Link to={`${ROUTES.NOTICE}/${ROUTES.CREATE}`}>
            //       介護スタッフ登録
            //     </Link>
            //   ),
            // },
          },
        ],
      },
      {
        path: ROUTES.CONTACT_CHAT,
        element: <ContactChatView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.CONTACT_CHAT}>個別連絡</Link>,
        },
      },
      {
        path: ROUTES.COMMENT_DETAIL,
        element: <CommentDetailView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.COMMENT_DETAIL}>掲示板</Link>,
        },
      },
      {
        path: ROUTES.POST_DETAIL,
        element: <PostDetailView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.POST_DETAIL}>掲示板</Link>,
        },
      },
      {
        path: ROUTES.STAFF,
        handle: {
          breadcrumb: () => <Link to={ROUTES.STAFF}>介護スタッフ一覧</Link>,
        },
        children: [
          {
            path: "",
            element: <StaffList />,
          },
          {
            path: ROUTES.CREATE,
            element: <StaffDetail />,
            handle: {
              breadcrumb: (data: any) => (
                <Link to={`${ROUTES.STAFF}/${ROUTES.CREATE}`}>
                  介護スタッフ登録
                </Link>
              ),
            },
          },
          {
            path: ROUTES.ID,
            loader: async ({ params }) => {
              return params.id;
            },
            handle: {
              breadcrumb: (id: any) => (
                <Link to={`${ROUTES.STAFF}/${id}`}>{id}</Link>
              ),
            },
            children: [
              {
                path: "",
                element: <StaffDetail />,
              },
              {
                path: ROUTES.EDIT_HISTORY,
                element: <StaffChangeHistory />,
                loader: async ({ params }) => {
                  return params.id;
                },
                handle: {
                  breadcrumb: () => (
                    <Link to="" onClick={(e) => e.preventDefault()}>
                      変更履歴一覧
                    </Link>
                  ),
                },
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.SHIFT,
        handle: {
          breadcrumb: () => (
            <Link to={`${ROUTES.SHIFT}/${ROUTES.SHIFT_MANAGEMENT}`}>
              実際登録
            </Link>
          ),
        },
        children: [
          {
            path: ROUTES.SHIFT_CLIENT,
            //element: <ShiftPage activeKey={ROUTES.SHIFT_CLIENT} />,
            element: <ShiftPage />,
          },
          {
            path: ROUTES.SHIFT_STAFF,
            element: <ShiftPage activeKey={ROUTES.SHIFT_STAFF} />,
          },

          {
            path: ROUTES.SHIFT_HOLIDAY,
            element: <ShiftPage activeKey={ROUTES.SHIFT_HOLIDAY} />,
          },
          {
            path: ROUTES.REGISTER,
            element: <ShiftRegistrationDetail />,
            handle: {
              breadcrumb: (data: any) => (
                <Link to={`${ROUTES.REGISTER}`}>Register</Link>
              ),
            },
          },
          {
            path: ROUTES.SHIFT_MANAGEMENT,
            element: <ShiftManagement />,
          },
          {
            path: `${ROUTES.SHIFT_MANAGEMENT}/:id`,
            element: <DailyFormPage />,
          },
        ],
      },
      {
        path: ROUTES.RECORD,
        children: [
          {
            path: ROUTES.CUSTOMER, //equal to path /record/customer
            element: <RecordEachCustomer />,
          },
        ],
      },
      {
        path: ROUTES.CUSTOMER,
        handle: {
          breadcrumb: (data: any) => (
            <Link to={`${ROUTES.CUSTOMER}`}>お客様一覧</Link>
          ),
        },
        children: [
          {
            path: "",
            element: <CustomerList />,
          },
          {
            path: ROUTES.HISTORY_CUSTOMER,
            children: [
              {
                path: ROUTES.ID, // equal to path /record-history-customer/:id
                children: [
                  {
                    path: "",
                    element: <CustomerHistoryList />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTES.CREATE_RECORD_DAILY,
            children: [
              {
                path: ROUTES.ID, // equal to path /create-record-daily/:id
                children: [
                  {
                    path: "",
                    element: <CreateRecord />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTES.CREATE,
            element: <CustomerDetail />,
            handle: {
              breadcrumb: (data: any) => (
                <Link to={`${ROUTES.CUSTOMER}/${ROUTES.CREATE}`}>
                  お客様登録
                </Link>
              ),
            },
          },
          {
            path: ROUTES.ID, // equal to path /customer/:id
            loader: async ({ params }) => {
              return params.id;
            },
            handle: {
              breadcrumb: (id: any) => (
                <Link to={`${ROUTES.CUSTOMER}/${id}`}>{id}</Link>
              ),
            },
            children: [
              {
                path: "",
                element: <CustomerDetail />,
              },
              {
                path: ROUTES.EDIT_HISTORY,
                loader: async ({ params, request }) => {
                  return params.id;
                },
                handle: {
                  breadcrumb: (id: any) => (
                    <Link to="" onClick={(e) => e.preventDefault()}>
                      変更履歴一覧
                    </Link>
                  ),
                },
                element: <CustomerChangeHistory />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.NURSING_CARE,
        children: [
          {
            path: "",
            element: <AdminNursingCareRecord />,
          },
          {
            path: ROUTES.ID,
            element: <NursingCareRecordDetail />,
          },
          {
            path: ROUTES.RECORDDAILYADMIN,
            element: <RecordDailyMaster />,
          },
        ],
      },

      {
        children: [
          {
            path: ROUTES.MENU_PROFILE,
            element: <ProfileDetail />,
          },
          {
            path: ROUTES.CARE_SERVICE,
            element: <CareService />,
          },
          {
            path: ROUTES.FACILITY_REGISTRATION_LIST,
            element: <FacilityList />,
          },
          {
            path: ROUTES.FACILITY_REGISTRATION_ADD,
            element: (
              <FacilityDetail
                difference={{
                  type: "add",
                  title: "新規登録",
                }}
              />
            ),
          },
          {
            path: ROUTES.FACILITY_REGISTRATION_EDIT,
            element: (
              <FacilityDetail
                difference={{
                  type: "edit",
                  title: "編集",
                }}
              />
            ),
          },
          {
            path: ROUTES.FACILITY_REGISTRATION_DETAIL,
            element: (
              <FacilityDetail
                difference={{
                  type: "view",
                  title: "詳細",
                }}
              />
            ),
          },
          {
            path: ROUTES.CARE_CATEGORY_SETTINGS,
            element: <SettingNursingCareLevel />,
          },
        ],
      },
      {
        path: ROUTES.PAYMENT,
        handle: {
          breadcrumb: () => <Link to={ROUTES.PAYMENT}>請求・入金履歴</Link>,
        },
        children: [
          {
            path: "",
            element: <PaymentPage />,
          },
          // {
          //   path: ROUTES.DETAIL,
          //   element: <DepositDetail />,
          // },
          {
            path: ROUTES.USAGE,
            element: <UsageHistory />,
            loader: async ({ params, request }) => {
              switch (new URL(request.url).searchParams.get("type") as TUsage) {
                case "equipment":
                  return "備品利用";
                case "service":
                  return "サービス利用";
                case "potoro":
                  return "ぽとろ利用";
                default:
                  return "備品利用";
              }
            },
            handle: {
              breadcrumb: (type: any) => (
                <Link to="" onClick={(e) => e.preventDefault()}>
                  {type}
                </Link>
              ),
            },
          },
          {
            path: ROUTES.RECEIPT,
            element: <ReceiptRegister />,
            handle: {
              breadcrumb: () => (
                <Link to="" onClick={(e) => e.preventDefault()}>
                  立替レシート登録
                </Link>
              ),
            },
          },
          {
            path: ROUTES.BILLING,
            children: [
              {
                path: ROUTES.CAREPLAN,
                element: <CareplanBilling />, // fallback element
                loader: async ({ params, request }) => {
                  switch (
                    new URL(request.url).searchParams.get(
                      "type"
                    ) as TPatientType
                  ) {
                    case "nursing_care":
                      return "利用請求書(介護)";
                    case "disability":
                      return "利用請求書(障害)";
                    default:
                      return "利用請求書(介護)";
                  }
                },

                handle: {
                  breadcrumb: (type: any) => (
                    <Link to="" onClick={(e) => e.preventDefault()}>
                      {type}
                    </Link>
                  ),
                },
              },
              {
                path: ROUTES.RENT,
                element: <RentBilling />, // fallback element
                handle: {
                  breadcrumb: () => (
                    <Link to="" onClick={(e) => e.preventDefault()}>
                      家賃等請求
                    </Link>
                  ),
                },
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.SHIFT_MANAGEMENT_STAFF,
        handle: {
          breadcrumb: () => (
            <Link to="" onClick={(e) => e.preventDefault()}>
              シフト管理　休日・出勤申請
            </Link>
          ),
        },
        children: [
          {
            path: "",
            element: <ManageShiftStaff role={ROLE.ADMIN}/>,
          },
        ],
      },
    ],
  },
  {
    path: "",
    element: <Navigate to={ROUTES.ADMIN} />,
  },
];

const adminRouter = createBrowserRouter([...routesForAllRole, ...adminRoute]);

//--- Staff router
const staffRoute: RouteObject[] = [
  {
    path: ROUTES.STAFF,
    element: (
      <ProtectedRoutes redirectTo={`/${ROUTES.STAFF}/${ROUTES.LOGIN}`}>
        <StaffLayout>
          <Outlet />
        </StaffLayout>
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "",
        element: <Navigate to={ROUTES.NURSING_CARE} />,
      },
      {
        path: ROUTES.NOTICE,
        element: <Notice />,
      },
      {
        path: ROUTES.SHIFT_MANAGEMENT_STAFF,
        children: [
          { path: "", element: <ManageShiftStaff role={ROLE.STAFF} /> },
        ],
      },
      {
        path: ROUTES.CUSTOMER,
        children: [
          { path: "", element: <CustomerList role={ROLE.STAFF} /> },
          {
            path: ROUTES.ID, // equal to path /customer/:id
            element: <CustomerDetail role={ROLE.STAFF} />,
          },
        ],
      },
      {
        path: ROUTES.SHIFT,
        element: <ShiftPage />,
      },
      {
        path: ROUTES.RECORD,
        children: [
          {
            path: ROUTES.HISTORY, // equal to path /record/history
            children: [
              {
                path: ROUTES.DAILY, // equal to path /record/history/daily
                element: <HistoryRecordDaily />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.MONTHSHIFTBEFORE,
        element: <NextMonthShiftBefore />,
      },
      {
        path: ROUTES.MONTHSHIFTAFTER,
        element: <NextMonthShiftAfter />,
      },
      {
        path: ROUTES.SCHEDULE,
        element: <VisitSchedulePage />,
      },
      {
        path: ROUTES.BULLETINBOARD,
        element: <PostList />,
      },
      {
        path: ROUTES.CREATE_BULLETINBOARD,
        element: <CreatePost />,
      },
      {
        path: ROUTES.BULLETINBOARDCONTENT,
        element: <CommentDetail />,
      },
      {
        path: ROUTES.STAFF_POST_ADD,
        element: (
          <PostDetail
            difference={{
              type: "add",
              title: "詳細",
            }}
          />
        ),
      },
      {
        path: ROUTES.STAFF_POST_EDIT,
        element: (
          <PostDetail
            difference={{
              type: "edit",
              title: "詳細",
            }}
          />
        ),
      },
      {
        path: ROUTES.CONTACT_LIST,
        element: <ContactList />,
      },
      {
        path: ROUTES.CONTACT_CHAT,
        element: <ContactChat />,
      },
      {
        path: ROUTES.RECORDHISTORYDAILY,
        children: [
          {
            path: ROUTES.ID,
            element: <HistoryRecordDaily />,
          },
        ],
      },
      // {
      //   path: ROUTES.VISITSCHEDULE,
      //   element: <VisitSchedulePage />,
      // },
      // {
      //   path: ROUTES.ROUTINES,
      //   element: <StaffRoutine />,
      // },
      {
        path: ROUTES.RECORDHISTORYDAILY, // equal to path /record/history/daily
        element: <HistoryRecordDaily />,
      },
      {
        path: ROUTES.RECORDBYDAY,
        element: <CustomerHistoryDetail />,
      },
      // {
      //   path: ROUTES.MONTHSHIFTBEFORE,
      //   element: <NextMonthShiftBefore />,
      // },
      // {
      //   path: ROUTES.MONTHSHIFTAFTER,
      //   element: <NextMonthShiftAfter />,
      // },
      {
        path: ROUTES.HISTORYCUSTOMERNAME,
        children: [
          {
            path: ROUTES.ID,
            element: <HistoryCustomerNameList />,
          },
        ],
      },
      {
        path: ROUTES.HISTORYCUSTOMERLIST,
        element: <HistoryCustomerList />,
      },
      {
        path: ROUTES.NURSING_CARE,
        children: [
          {
            path: "",
            element: <StaffNursingCareRecord />,
          },
          {
            path: ROUTES.ID,
            element: <NursingCareDetail />,
          },
          {
            path: ROUTES.HISTORY_CUSTOMER + "/:id",
            element: <StaffRecordHistoryCustomer />,
          },
          {
            path: ROUTES.CREATE_RECORD_DAILY + "/:id",
            element: <StaffCreateRecordDaily />,
          },
        ],
      },
    ],
  },
  {
    path: "",
    element: <Navigate to={ROUTES.STAFF} />,
  },
];

const staffRouter = createBrowserRouter([...routesForAllRole, ...staffRoute]);

//--- Client router
const clientRoute: RouteObject[] = [
  {
    path: "",
    element: (
      <ProtectedRoutes redirectTo={`/${ROUTES.LOGIN}`}>
        <ClientLayout>
          <Outlet />
        </ClientLayout>
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "",
        element: <MenuClientPage />,
      },
      {
        path: CLIENTPATH.search,
        element: <SearchPage />,
      },
      {
        path: CLIENTPATH.visitSchedule,
        element: <ClientVisitSchedulePage />,
      },
      //{
      //  path: CLIENTPATH.historyList,
      //  element: <ClientHistoryList />,
      //},
      //{
      //  path: CLIENTPATH.history,
      //  element: <RecordHistory />,
      //},
      {
        path: CLIENTPATH.historyList,
        children: [
          {
            path: "",
            element: <ClientHistoryList />,
          },
          {
            path: ROUTES.ID,
            loader: async ({ params }) => {
              return params.id;
            },
            children: [
              {
                path: "",
                element: <RecordHistory />,
              },
            ],
          },
        ],
      },
    ],
  },
];

const clientRouter = createBrowserRouter([...routesForAllRole, ...clientRoute]);

//--- HS Admin router
const hsAdminRoute: RouteObject[] = [
  {
    path: ROUTES.HOME_SYSTEM,
    element: (
      <ProtectedRoutes redirectTo={`/${ROUTES.HOME_SYSTEM}/${ROUTES.LOGIN}`}>
        <HomeSystemLayout type="admin">
          <Outlet />
        </HomeSystemLayout>
      </ProtectedRoutes>
    ),
    handle: {
      breadcrumb: () => <Link to={`/${ROUTES.HOME_SYSTEM}`}>ホーム</Link>,
    },
    children: [
      {
        path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_DASHBOARD}`,
        handle: {
          breadcrumb: () => (
            <Link to={`/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_DASHBOARD}`}>
              ダッシュボード
            </Link>
          ),
        },
        children: [
          {
            path: "",
            element: <HSAdminDashboard />,
          },
        ],
      },
      {
        path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_CUSTOMERS}`,
        handle: {
          breadcrumb: () => (
            <Link to={`/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_CUSTOMERS}`}>
              お客様一覧
            </Link>
          ),
        },
        children: [
          {
            path: "",
            element: <HomeCustomers />,
          },
          {
            path: ROUTES.ID,
            loader: async ({ params }) => {
              return params.id;
            },
            handle: {
              breadcrumb: (id: any) => (
                <Link
                  to={`${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_CUSTOMERS}/${id}`}
                >
                  {id}
                </Link>
              ),
            },
            children: [
              {
                path: "",
                element: <HomeCustomerDetail />,
              },
              {
                path: "preview",
                element: <HomeSchedule />,
              },
            ],
          },
        ],
      },
      {
        path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_SCHEDULES}`,
        handle: {
          breadcrumb: () => (
            <Link to={`/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_SCHEDULES}`}>
              ホーム　予定
            </Link>
          ),
        },
        children: [
          {
            path: "",
            element: <HomeSchedule />,
          },
          {
            path: ROUTES.ID,
            loader: async ({ params }) => {
              return params.id;
            },
            handle: {
              breadcrumb: (id: any) => (
                <Link
                  to={`${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_SCHEDULES}/${id}`}
                >
                  {id}
                </Link>
              ),
            },
            children: [
              {
                path: "",
                element: <HomeServiceDeviceList />,
              },
            ],
          },
        ],
      },
      {
        path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_EQUIPMENT_SERVICES}`,
        handle: {
          breadcrumb: () => (
            <Link
              to={`${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_EQUIPMENT_SERVICES}`}
            >
              備品利用一覧
            </Link>
          ),
        },
        children: [
          {
            path: "",
            element: <HomeServiceDeviceList />,
          },
        ],
      },
      {
        path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_NURSING_CARE}`,
        handle: {
          breadcrumb: () => (
            <Link to={`/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_NURSING_CARE}`}>
              介護内容　一覧
            </Link>
          ),
        },
        children: [
          {
            path: "",
            element: <HomeNursingCareList />,
          },
          {
            path: ROUTES.CREATE,
            element: <HomeNursingCareDetail />,
            handle: {
              breadcrumb: (data: any) => (
                <Link to={`${ROUTES.STAFF}/${ROUTES.CREATE}`}>Create</Link>
              ),
            },
          },
          {
            path: ROUTES.ID,
            loader: async ({ params }) => {
              return params.id;
            },
            handle: {
              breadcrumb: (id: any) => (
                <Link
                  to={`${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_NURSING_CARE}/${id}`}
                >
                  {id}
                </Link>
              ),
            },
            children: [
              {
                path: "",
                element: <HomeNursingCareDetail />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.NOTICE,
        handle: {
          breadcrumb: () => <Link to={ROUTES.NOTICE}>お知らせ</Link>,
        },
        children: [
          {
            path: "",
            element: <HSAdminNotice />,
          },
          {
            path: ROUTES.CREATE,
            element: <HSAdminCreateNoti />,
          },
        ],
      },
      {
        path: ROUTES.CONTACT_CHAT,
        element: <HSAdminContactChatView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.CONTACT_CHAT}>個別連絡</Link>,
        },
      },
      {
        path: ROUTES.COMMENT_DETAIL,
        element: <HSAdminCommentDetailView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.COMMENT_DETAIL}>掲示板</Link>,
        },
      },
      {
        path: ROUTES.POST_DETAIL,
        element: <HSAdminPostDetailView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.POST_DETAIL}>掲示板</Link>,
        },
      },
    ],
  },
  {
    path: "",
    element: (
      <Navigate to={`/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_DASHBOARD}`} />
    ),
  },
];

const hsAdminRouter = createBrowserRouter([
  ...routesForAllRole,
  ...hsAdminRoute,
]);

//--- HS Staff router
const hsStaffRoute: RouteObject[] = [
  {
    path: ROUTES.HOME_SYSTEM_STAFF,
    element: (
      <ProtectedRoutes
        redirectTo={`/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.LOGIN}`}
      >
        <HomeSystemLayout
          isHeader={true}
          title="ホーム　予定"
          isShowSidebar={true}
        >
          <Outlet />
        </HomeSystemLayout>
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "",
        element: <HomeSystemDashboard />,
      },
      {
        path: `/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.HOME_SCHEDULES}`,
        children: [
          {
            path: "",
            element: <HomeSchedule />,
          },
          {
            path: ROUTES.ID,
            loader: async ({ params }) => {
              return params.id;
            },
            children: [
              {
                path: "",
                element: <HomeServiceDeviceList />,
              },
            ],
          },
        ],
      },
      {
        path: `/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.HOME_EQUIPMENT_SERVICES}`,
        element: <HomeServiceDeviceList />,
      },
      {
        path: ROUTES.NOTICE,
        handle: {
          breadcrumb: () => <Link to={ROUTES.NOTICE}>お知らせ</Link>,
        },
        children: [
          {
            path: "",
            element: <HSStaffNotice />,
          },
          {
            path: ROUTES.CREATE,
            element: <HSStaffCreateNoti />,
          },
        ],
      },
      {
        path: ROUTES.CONTACT_CHAT,
        element: <HSStaffContactChatView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.CONTACT_CHAT}>個別連絡</Link>,
        },
      },
      {
        path: ROUTES.COMMENT_DETAIL,
        element: <HSStaffCommentDetailView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.COMMENT_DETAIL}>掲示板</Link>,
        },
      },
      {
        path: ROUTES.POST_DETAIL,
        element: <HSAdminPostDetailView />,
        handle: {
          breadcrumb: () => <Link to={ROUTES.POST_DETAIL}>掲示板</Link>,
        },
      },
    ],
  },
  {
    path: "",
    element: <Navigate to={ROUTES.HOME_SYSTEM_STAFF} />,
  },
];

const hsStaffRouter = createBrowserRouter([
  ...routesForAllRole,
  ...hsStaffRoute,
]);

//--- All router
const allRouter = createBrowserRouter([
  ...clientRoute,
  ...adminRoute,
  ...staffRoute,
  ...hsAdminRoute,
  ...hsStaffRoute,
  ...routesForAllRole,
]);

export default function AppRoutes() {
  const { isLoading } = useAuthenticate();
  const { role } = useSelector((state: RootState) => state.user);

  const router = useMemo(() => {
    switch (role) {
      case ROLE.ADMIN:
        return adminRouter;
      case ROLE.STAFF:
        return staffRouter;
      case ROLE.CLIENT:
        return clientRouter;
      case ROLE.HS_ADMIN:
        return hsAdminRouter;
      case ROLE.HS_STAFF:
        return hsStaffRouter;
      default:
        return allRouter;
    }
  }, [role]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  router.subscribe(() => {
    scrollToTop();
  });

  if (!isLoading) {
    return <RouterProvider router={router} />;
  }

  return <></>;
}
